import React, { useContext } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import DateComponent from "../components/date";
import { Link } from "../components/links";
import ReactContext from "../context/reactContext";
import EntryPage from "../components/EntryPage";
import "../styles/pages/blog.scss";

const Blog = ({ data }) => {
  const { blogCategory } = useContext(ReactContext);
  const allPosts = data.allContentfulBlogPost.edges;
  return (
    <>
      {/* <EntryPage text="blog" /> */}
      <div className="db-blog">
        <div className="db-blog__inner">
          {blogCategory &&
            !allPosts.find((el) => el.node.category.includes(blogCategory)) && (
              <h2>No results found.</h2>
            )}
          {allPosts
            .filter((el) =>
              blogCategory ? el.node.category.includes(blogCategory) : true
            )
            .map((post) => {
              const {
                title,
                blogAuthor,
                contentful_id,
                publish,
                image,
                blogSummary,
                createdDate,
                category,
              } = post.node;
              return (
                <>
                  {publish && (
                    <div key={title} className="db-blog__post">
                      <div className="db-blog__post__content">
                        <h6>Blog</h6>
                        <Link
                          className="db-blog__post__content__title cursor-pointer"
                          to={contentful_id}
                        >
                          <h3 className="db-blog__post__content__title">
                            {title}
                          </h3>
                        </Link>
                        <p className="db-blog__post__content__created">
                          <DateComponent date={createdDate} />
                        </p>
                        <Link
                          className="db-blog__post__content__summary cursor-pointer"
                          to={contentful_id}
                        >
                          <p className="db-blog__post__content__summary">
                            {blogSummary}
                          </p>
                        </Link>
                        <Link className="cursor-pointer" to={contentful_id}>
                          Read more
                        </Link>
                      </div>
                      <Link to={contentful_id}>
                        <GatsbyImage
                          alt={title}
                          image={image[0].gatsbyImageData}
                          className="cursor-pointer"
                        />
                      </Link>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Blog;

export const query = graphql`
  {
    allContentfulBlogPost {
      edges {
        node {
          blogAuthor
          contentful_id
          title
          publish
          category
          image {
            gatsbyImageData(width: 580)
          }
          blogSummary
          createdDate
        }
      }
    }
  }
`;
