import React from "react";

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}


const DateComponent = (props) => {
  return <div>{formatDate(props.date)}</div>;
}

export default DateComponent;
